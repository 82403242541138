import { applyMiddleware, createStore } from "redux";
import reducer from "../reducers";

import { createFirebaseMiddleware } from "./firebase";

const FirebaseMiddleware = createFirebaseMiddleware();

export function create() {
  return createStore(reducer, applyMiddleware(FirebaseMiddleware));
}
