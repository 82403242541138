import * as React from "react";
import classnames from "classnames";
import { playRandomSound } from "../utils";

const answers = [
  "Very Accurate",
  "Moderately Accurate",
  "Neither Accurate Nor Inaccurate",
  "Moderately Inaccurate",
  "Very Inaccurate"
];

interface IQuestionProps {
  question: string;
  selection: number;
  onChange: (index: number) => void;
}

const Question: React.SFC<IQuestionProps> = props => (
  <div className="questionContainer">
    <h4>{props.question}</h4>
    <div className="btn-group-vertical" role="group">
      {answers.map((answer, index) => {
        return (
          <button
            key={`input-${index}`}
            onClick={e => {
              e.preventDefault();
              playRandomSound();
              props.onChange(index);
            }}
            className={classnames("thebutton", {
              active: props.selection === index
            })}
          >
            {answer}
          </button>
        );
      })}
    </div>
  </div>
);

export default Question;
