import "./stylesheets/main.scss";
import "bootstrap/dist/css/bootstrap.css";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";

import * as Store from "./store";
import Application from "./components/Application";

const store = Store.create();

ReactDOM.render(
  <Provider store={store}>
    <Application />
  </Provider>,
  document.getElementById("app")
);
