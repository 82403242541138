import { Action, ActionTypes } from "../actions";

export interface IAppState {
  connected: boolean;
}

const initialState: IAppState = {
  connected: false
};

export default (state = initialState, action: Action): IAppState => {
  switch (action.type) {
    case ActionTypes.WEBSOCKET_CONNECTED: {
      const { connected } = action;
      return { ...state, connected };
    }

    default:
      return state;
  }
};
