// @ts-ignore: Unreachable code error
import Tone from "tone";

export function randomElement(arr: any[]) {
  const index = Math.floor(Math.random() * arr.length);
  return arr[index];
}

const notificationPlaybackRate = [
  0.793701,
  0.890899,
  1,
  1.122462,
  1.259921,
  1.414214,
  1.587401
];

const notificationPlayer = new Tone.Player(
  "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/iMessage.mp3"
).toMaster();

const bliepPlaybackRate = [0.5, 1.0, 1.5, 2];

const bliepPlayers = [
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-1-Ab.mp3"
  ).toMaster(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-1-Bb.mp3"
  ).toMaster(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-1-C.mp3"
  ).toMaster(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-1-Eb.mp3"
  ).toMaster(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-1-G.mp3"
  ).toMaster(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-2-Ab.mp3"
  ).toMaster(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-2-Bb.mp3"
  ).toMaster(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-2-C.mp3"
  ).toMaster(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-2-Eb.mp3"
  ).toMaster(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-2-G.mp3"
  ).toMaster()
];

export function playNotification() {
  notificationPlayer.playbackRate = randomElement(notificationPlaybackRate);
  notificationPlayer.start();
}

export function playRandomSound() {
  const randomPlayer = randomElement(bliepPlayers);
  randomPlayer.playbackRate = randomElement(bliepPlaybackRate);
  randomPlayer.start();
}

const dronePlayers = [
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/larp-low.mp3"
  ).toMaster(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/larp-mid.mp3"
  ).toMaster(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/larp-high.mp3"
  ).toMaster()
];

dronePlayers.forEach(player => {
  player.loop = true;
  player.volume.value = -12;
});

let currentlyPlayingDrone: number = Math.floor(
  Math.random() * dronePlayers.length
);

export function startDrone() {
  dronePlayers[currentlyPlayingDrone].stop();
  const nextDrone = ((currentlyPlayingDrone || 0) + 1) % dronePlayers.length;
  dronePlayers[nextDrone].start();
  currentlyPlayingDrone = nextDrone;
}

export function stopDrones() {
  dronePlayers.forEach(player => player.stop());
}
