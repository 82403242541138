import { Action, ActionTypes } from "../actions";

export interface IDataState {
  state: string;
  color: string;
  answers?: [];
}

const initialState: IDataState = {
  state: "",
  color: "#ff0000",
  answers: undefined
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.UPDATE: {
      const { __v, ...data } = action.data;
      return action.merge ? { ...state, ...data } : data;
    }

    case ActionTypes.WEBSOCKET_CONNECTED: {
      if (action.connected === false) {
        console.log("Data Reducer: WEBSOCKET_DISCONNECTED");
        return {
          ...state,
          history: [],
          questions: []
        };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
};
