import * as firebase from "firebase/app";
import "firebase/database";

import { Middleware } from "redux";
import { Action, ActionTypes, createUpdateAction } from "../reducers/actions";
import { playNotification } from "../utils";

const localStorageUUIDKey = "smartphoneorchestra-datadriven";

export function createFirebaseMiddleware(): Middleware {
  return store => {
    initialiseFirebase();
    firebase.database.enableLogging(true);
    const database = firebase.database();
    const root = database.ref().child("datadriven");
    const usersRef = root.child("users");

    setTimeout(() => {
      const userRef = getUserRef(usersRef);
      userRef.on("value", snapshot => {
        const value = snapshot.val();
        if (value) {
          const data = { _id: userRef.key, ...snapshot.val() };
          store.dispatch(createUpdateAction(data, false));
        } else {
          store.dispatch(createUpdateAction({}, false));
        }
      });

      database.ref(".info/connected").on("value", snapshot => {
        const online = snapshot ? snapshot.val() : true;
        userRef.update({ online });
      });
      userRef.onDisconnect().update({ online: false });
    }, 10);

    return (next: any) => {
      return (action: Action) => {
        if (action.type === ActionTypes.FLUSH_CHOICES) {
          const userRef = usersRef.child(store.getState().data._id);
          userRef.child("answers").set(action.answers);
        }
        next(action);
      };
    };
  };
}

function getUserRef(usersRef: firebase.database.Reference) {
  const localId = localStorage.getItem(localStorageUUIDKey);
  if (localId) {
    return usersRef.child(localId);
  } else {
    const userRef = usersRef.push({ online: true });
    localStorage.setItem(localStorageUUIDKey, userRef.key as string);
    return userRef;
  }
}

function initialiseFirebase() {
  firebase.initializeApp({
    apiKey: "AIzaSyCAtjCfb1kp7q3chyUPybRLSojhkPuehHE",
    databaseURL: "https://spo-playground.firebaseio.com/"
  });
}
