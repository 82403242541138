import { combineReducers } from 'redux'
import AppReducer, { IAppState } from './app'
import DataReducer, { IDataState } from './data'

export interface IRootState {
	app: IAppState
	data: IDataState
}

export default combineReducers({
	app: AppReducer,
	data: DataReducer
})
