import * as React from "react";
import { playNotification, startDrone, stopDrones } from "../utils";

interface IColorProps {
  color: string;
}

const Color: React.SFC<IColorProps> = ({ color }) => {
  React.useEffect(() => {
    playNotification();
    startDrone();
    return () => {
      stopDrones();
    };
  }, [color]);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: color
      }}
    />
  );
};

export default Color;
