export enum ActionTypes {
  WEBSOCKET_CONNECTED = "WEBSOCKET_CONNECTED",
  UPDATE = "UPDATE",
  FLUSH_CHOICES = "FLUSH_CHOICES"
}

export function createAction<T extends { type: ActionTypes }>(d: T): T {
  return d;
}

export function createConnectedAction(connected: boolean) {
  return createAction({
    type: ActionTypes.WEBSOCKET_CONNECTED,
    connected
  });
}

export function createUpdateAction(data: any, merge: boolean) {
  return createAction({
    type: ActionTypes.UPDATE,
    data,
    merge
  });
}

export function createFlushChoicesAction(answers: Array<number>) {
  return createAction({
    type: ActionTypes.FLUSH_CHOICES,
    answers
  });
}

export type Action = ReturnType<
  | typeof createConnectedAction
  | typeof createUpdateAction
  | typeof createFlushChoicesAction
>;
