import * as React from "react";
import { connect } from "react-redux";
import { IRootState } from "../reducers";
import { Dispatch, bindActionCreators } from "redux";
import { createFlushChoicesAction } from "../reducers/actions";

import Questionnaire from "./Questionnaire";
import Color from "./Color";

interface IApplicationProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const Application: React.SFC<IApplicationProps> = props => {
  if (props.data.state === "color") {
    return <Color color={props.data.color} />;
  } else if (props.data.answers) {
    return <div className={'questionContainer'}><h3>All done!</h3></div>;
  } else {
    return <Questionnaire onSave={v => props.createFlushChoicesAction(v)} />;
  }
};

const mapStateToProps = (state: IRootState) => {
  return state;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createFlushChoicesAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Application);
