import * as React from "react";
import Question from "./Question";
import { playRandomSound } from "../utils";

const questions = [
  "Am the life of the party.",
  "I Don't talk a lot.",
  "I Am relaxed most of the time.",
  "Have difficulty understanding abstract ideas.",
  "I Have a vivid imagination.",
  "I Keep in the background.",
  "I Sympathize with others' feelings.",
  "I Make a mess of things.",
  "Seldom feel blue.",
  "Am not interested in abstract ideas.",
  "I Get chores done right away.",
  "Often forget to put things back in their proper place.",
  "Get upset easily.",
  "Do not have a good imagination.",
  "I Talk to a lot of different people at parties.",
  "Am not really interested in others.",
  "I Like order.",
  "I Have frequent mood swings.",
  "I Feel others' emotions.",
  "I am not interested in other people’s problems"
];

interface IQuestionnaireProps {
  onSave: (answers: Array<number>) => void;
}

const Questionnaire: React.SFC<IQuestionnaireProps> = props => {
  const [answers, setAnswers] = React.useState<Array<number>>([]);
  const [questionIndex, setQuestionIndex] = React.useState(0);

  console.log(answers);

  const setValueOfIndex = (index: number, value: number) => {
    const copy = [...answers];
    copy[index] = value;
    setAnswers(copy);
  };

  const allQuestionsAnswered = () => {
    const answered = answers.filter(v => v !== undefined);
    return answered.length === questions.length;
  };

  if (questionIndex < questions.length) {
    return (
      <div className="fullContainer">
        <Question
          question={questions[questionIndex]}
          selection={
            answers[questionIndex] !== undefined ? answers[questionIndex] : -1
          }
          onChange={value => {
            setValueOfIndex(questionIndex, value);
            setQuestionIndex(questionIndex + 1);
          }}
        />
        {questionIndex > 0 && (
          <button
            className="previousButton btn btn-outline-primary btn-block"
            onClick={() => setQuestionIndex(questionIndex - 1)}
          >
            Previous
          </button>
        )}
      </div>
    );
  } else {
    return (
      <div className="fullContainer">
        <div className="questionContainer">
          <button
            className="btn btn-success btn-block"
            disabled={!allQuestionsAnswered()}
            onClick={() => {
              playRandomSound();
              props.onSave(answers);
            }}
          >
            Save
          </button>
        </div>
        <button className="previousButton btn btn-outline-primary btn-block ">
          Previous
        </button>
      </div>
    );
  }
};

export default Questionnaire;
